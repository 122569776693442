// Core
import React from "react"
import Img from "gatsby-image"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import Button from "../button/button"
import Share from "../share/share"
import SplitText from "../split-text/split-text"

// Styles
import styles from "./press-item.module.scss"

const PressItem = props => {
  const { thumbnail } = props,
    { itemTitle } = props,
    { dateOfPublish } = props,
    { link } = props

  if (!link) return null

  let btnProps = {
    buttonText: "Read More",
    typeOfLink: link.link,
  }

  return (
    <React.Fragment>
      <InView triggerOnce={true} threshold={0.2}>
        {({ inView, ref, entry }) => (
          <div
            className={`container ${styles.pressItem}`}
            ref={ref}
            inview={inView.toString()}
          >
            <div className={`cols flex flex--center flex--vert-align`}>
              {thumbnail && (
                <div className={`col m12 t4 d3 ${styles.pressThumb}`}>
                  <div className={styles.pressThumbInner}>
                    <Img fluid={thumbnail.fluid} alt={thumbnail.description} />
                  </div>
                </div>
              )}
              <div
                className={`${thumbnail ? `col m12 t8 d7` : `col m12 t12 d10`}`}
              >
                <div
                  className={`${styles.pressText} ${
                    thumbnail ? styles.pressTextPadded : ``
                  }`}
                >
                  {dateOfPublish && (
                    <span className={styles.date}>{dateOfPublish}</span>
                  )}
                  <div className={styles.share}>
                    <Share
                      internal={
                        link.link.__typename !== "ContentfulExternalLink"
                      }
                      title={itemTitle}
                      url={link.link.slug || link.link.buttonDestination}
                    />
                  </div>
                  <SplitText classString={`${styles.copy} copy--m`}>
                    {itemTitle}
                  </SplitText>
                  <Button buttonProps={btnProps} />
                </div>
              </div>
            </div>
          </div>
        )}
      </InView>
    </React.Fragment>
  )
}

export default PressItem
