// Core
import React from "react"

// InView detector
import "intersection-observer"
import { InView } from "react-intersection-observer"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-heading/split-heading"

// Styles
import styles from "./hero.module.scss"

//
import Arrow from "../../../static/images/svg/next-arrow.inline.svg"

const Hero = props => {
  const { heroData } = props,
    colourScheme = "dark"

  let textContent

  textContent = (
    <div className={styles.heading}>
      <SplitHeading h1={true} classString={`h2`}>
        {heroData.heroTitle}
      </SplitHeading>
    </div>
  )

  return (
    <ScrollSection>
      <section
        className={`${styles.hero} ${styles.pressHero} ${
          colourScheme === "dark" ? styles.herodark : styles.heroblue
        }`}
      >
        <div className="container">
          <InView triggerOnce={true} threshold={0}>
            {({ inView, ref, entry }) => (
              <div className={`cols flex ${styles.cols}`}>
                <div
                  className={`col m12 t10 ${styles.text}`}
                  ref={ref}
                  inview={inView.toString()}
                  data-scroll
                  data-scroll-speed={0.6}
                  data-scroll-delay={0.6}
                >
                  {textContent}
                  <div className={`${styles.infoSection}`}>
                    <div className={styles.infoUnit}>
                      <h3>For Media inquiries contact</h3>
                      <div className={`${styles.infoUnitAction}`}>
                        <a href={`mailto: Kayli@120over80mktg.com`}>
                          Kayli@120over80mktg.com
                          <span className={styles.arrowWrap}>
                            <span className={styles.arrow}>
                              <Arrow />
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Hero
