// Core
import React from "react"

// Components
import PressItem from "../press-item/press-item"
import ScrollSection from "../locomotiveScroll/scrollSection"

// Styles
import styles from "./press-items.module.scss"

// import ButtonCircle from "./../../../static/images/svg/button-circle.inline.svg"
// import Arrow from "./../../../static/images/svg/arrow.inline.svg"

const PressItems = props => {
  const { items } = props

  // const [filter, setFilter] = useState("all")

  // const categories = useRef([])

  // const formatTag = string => {
  //   return string.toLowerCase()
  // }

  // const getFilters = () => {
  //   items.forEach(link => {
  //     let tags = link.tags

  //     if (!tags) return

  //     tags.forEach(tag => {
  //       tag = formatTag(tag)
  //       if (categories.current && !categories.current.includes(tag))
  //         categories.current.push(tag)
  //     })
  //   })

  //   categories.current && categories.current.sort()
  // }

  // getFilters()

  return (
    <React.Fragment>
      <ScrollSection>
        <section className={`${styles.pressItems}`}>
          {items.map((item, key) => {
            return <PressItem key={key} {...item} />
          })}
        </section>
      </ScrollSection>
    </React.Fragment>
  )
}

export default PressItems
