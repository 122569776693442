// Core
import React, { useState, useRef, useEffect } from "react"

//SVG
import Copy from "./../../../static/images/svg/copy.inline.svg"
import Twitter from "./../../../static/images/svg/twitter.inline.svg"
import Facebook from "./../../../static/images/svg/facebook.inline.svg"

// Styles
import styles from "./share.module.scss"

const Share = props => {
  const [open, setOpen] = useState(false)

  const facebookLink = useRef(),
    twitterLink = useRef(),
    copyLink = useRef()

  useEffect(() => {
    const sharedURL = props.internal
      ? window.location.origin + "/" + props.url
      : props.url
    const title = encodeURI(props.title),
      currentPage = encodeURI(sharedURL)

    const twitterLinkUrl = `https://twitter.com/intent/tweet?url=${currentPage}&text=${title}`
    const facebookLinkUrl = `https://www.facebook.com/dialog/share?app_id=230284832179809&display=popup&href=${currentPage}&redirect_uri=${currentPage}`

    if (twitterLink.current)
      twitterLink.current.setAttribute("href", twitterLinkUrl)
    if (facebookLink.current)
      facebookLink.current.setAttribute("href", facebookLinkUrl)
    if (copyLink.current) copyLink.current.value = currentPage
  }, [props.internal, props.title, props.url])

  const copyToClipboard = e => {
    e.preventDefault()
    if (copyLink.current) {
      copyLink.current.select()
      copyLink.current.setSelectionRange(0, 99999)
      document.execCommand("copy")
      setOpen(false)
      // alert("URL copied to clipboard")
    }
  }

  return (
    <div className={styles.share}>
      {props.internal ? (
        <button className={styles.shareButton} onClick={() => setOpen(true)}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      ) : (
        <button
          className={styles.shareButton}
          onClick={copyToClipboard}
          aria-label="Copy link to clipboard"
          title="Copy link to clipboard"
        >
          <Copy />
        </button>
      )}
      {props.internal && (
        <div className={`${styles.icons} ${open ? styles.open : ``}`}>
          <ul>
            <li>
              <a
                href="/"
                onClick={copyToClipboard}
                aria-label="Copy link to clipboard"
                className={styles.copyIcon}
                title="Copy link to clipboard"
              >
                <Copy />
              </a>
            </li>
            <li>
              <a
                href="/"
                onClick={() => setOpen(false)}
                target="_blank"
                aria-label="Share this page on Facebook"
                title="Share this page on Facebook"
                ref={facebookLink}
                className={styles.fbIcon}
              >
                <Facebook />
              </a>
            </li>
            <li>
              <a
                href="/"
                onClick={() => setOpen(false)}
                target="_blank"
                aria-label="Share this page on Twitter"
                title="Share this page on Twitter"
                ref={twitterLink}
                className={styles.twIcon}
              >
                <Twitter />
              </a>
            </li>
          </ul>
        </div>
      )}
      <textarea className={styles.textArea} tabIndex="-1" ref={copyLink} />
    </div>
  )
}

export default Share
