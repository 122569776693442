import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

//Components
import Footer from "../../components/footer/footer"
import Hero from "../../components/hero/press-hero"
import NextPage from "../../components/next-page/next-page"
import PressItems from "../../components/press-items/press-items"
import SEO from "../../components/seo/seo"

const PressPage = props => {
  const page = props,
    templateData = page.data.contentfulPressPage

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_LIGHT" })
    }
    header()
  }, [storeDispatch])

  let nextpageblock

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  } else {
    nextpageblock = (
      <NextPage
        heading={`Ready To Talk?`}
        title={`Get In Touch`}
        url={``}
        contact={true}
      />
    )
  }

  return (
    <React.Fragment>
      {templateData.seoData && (
        <SEO
          title={templateData.seoData.seoTitle}
          image={templateData.seoData.seoImage}
          description={templateData.seoData.seoDescription}
        />
      )}
      <Hero heroData={templateData} />
      <PressItems items={templateData.pressItems} />
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default PressPage

export const pageQuery = graphql`
  query pressPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPressPage(slug: { eq: $slug }) {
      ...pressPageQuery
    }
  }
`
